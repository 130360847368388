import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import Seo from "../components/seo"
import EnsLink from "../components/EnsLink/EnsLink"
import { GatsbyImage } from "gatsby-plugin-image"
import { useHeaderSettings } from "../hooks/Header/useHeaderSettings"
import "../sass/style.scss"
import { useTranslation } from "react-i18next"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data?.site?.siteMetadata?.title
  const settings = useHeaderSettings();
  const { t } = useTranslation();

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="404: Pagina nu a fost gasita" />
      <div className="error-page-header">
        <EnsLink link={{ url: settings?.logoLink?.url, target: settings?.logoLink?.target }}>
          <GatsbyImage
              image={settings?.logo?.logoOnBlack?.localFile?.childImageSharp?.gatsbyImageData}
              className="footer-logo"
              alt={settings?.logo?.altText || "ensys logo"}
            />
        </EnsLink>
      </div>
      <div className="error-page-container">
        <h1 className="error-title">404</h1>
        <p>{ t("Această pagină nu există. Vă rugăm reîncercați!") }</p>
        <Link to="/" className="error-page-redirect-btn">{ t("Înapoi la pagina principală") }</Link>
      </div>
    </Layout>
  )
}

NotFoundPage.defaultProps = {
  data: null,
  location: null,
}

NotFoundPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.string,
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
